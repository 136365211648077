import React from "react";
import { MenuNav } from "./MenuNav";
import { MenuList } from "./constants";

export const NavigationBar: React.FC = () => {
    return (
        <div className="hidden lg:flex xl:flex 2xl:flex m-4 text-xl justify-between px-5">
            <img src="adaptist-blue-logo.png" alt="logo" style={{ maxWidth: '240px' }} />
            <ul className="flex text-accelist-font items-center">
                {MenuList.map((value, key) => (
                    <MenuNav label={value.label} href={value.href} key={key} />
                ))}
            </ul>
        </div>
    )
}