import React, { useState } from 'react';
import { Title } from '../components/Title';
import { Page } from '../types/Page';
import Beranda from '../components/HomePage'
import { Button, ConfigProvider, Drawer, Layout, Menu, MenuProps } from 'antd';
import { ScoreModal } from '@/components/ScoreModal';
import { SurveyModal } from '@/components/SurveyModal';
import Head from 'next/head';
import { NavigationBar } from '@/components/NavigationBar';
import { useRouter } from 'next/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { CheckSecuritySection } from '@/components/Section/CheckSecuritySection';
import { FooterSection } from '@/components/Section/FooterSection';
import { AboutUsSection } from '@/components/Section/AboutUsSection'
import { FaqSection } from '@/components/Section/FaqSection';
// import { TestimoniesSection } from '@/components/Section/TestimoniesSection';
import { ContactUsSection } from '@/components/Section/ContactUsSection';
import { ProdukSection } from '@/components/Section/ProdukSection';
import CookieBanner from '@/components/CookieBanner';

const { Header, Content, Footer } = Layout;
const sidebarBackgroundColor = '#001529';
const Home: React.FC = () => {
    const [isSurveyModalOpen, setIsSurveyModalOpen] = useState(false);
    const [score, setScore] = useState(0);
    const [isModalScoreOpen, setIsModalScoreOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

    const showSurveyModal = () => {
        setIsSurveyModalOpen(true);
    };

    const handleSurveyModalOk = () => {
        const totalScore = selectedOptions.filter((value) => Number(value) === 1).length;
        const result = Math.round((totalScore / selectedOptions.length) * 100)
        setScore(result);
        setSelectedOptions([]);
        setIsSurveyModalOpen(false);
        setIsModalScoreOpen(true);
    }

    const handleSurveyModalCancel = () => {
        setSelectedOptions([]);
        setIsSurveyModalOpen(false);
    };

    const onChangeRadio = (id: number, val: string) => {
        const newSelectedOptions: string[] = [...selectedOptions];
        newSelectedOptions[id] = val;
        setSelectedOptions(newSelectedOptions);
    };

    const handleScoreModalOk = (action) => {
        setIsModalScoreOpen(false);
        if (action == 'hubungiKami') {
            router.push('/#kontak');
        }
    }

    const router = useRouter();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selected, setSelected] = useState([router.pathname]);

    function getMenu(): MenuProps['items'] {
        const menu: MenuProps['items'] = [];

        menu.push({
            key: '/#beranda',
            label: 'Beranda',
            onClick: () => {
                setDrawerOpen(false)
                router.push('/#beranda')
            }
        });

        menu.push({
            key: '/#tentang-kami',
            label: 'Tentang Kami',
            onClick: () => {
                setDrawerOpen(false)
                setTimeout(() => {
                    router.push('/#tentang-kami')
                }, 500)
            }
        });

        menu.push({
            key: '/#layananKami',
            label: 'Layanan Kami',
            onClick: () => {
                setDrawerOpen(false)
                setTimeout(() => {
                    router.push('/#layananKami')
                }, 500)
            }
        });

        menu.push({
            key: '/#kontak',
            label: 'Kontak',
            onClick: () => {
                setDrawerOpen(false)
                setTimeout(() => {
                    router.push('/#kontak')
                }, 500)
            }
        });

        menu.push({
            key: '/#faq',
            label: 'FAQ',
            onClick: () => {
                setDrawerOpen(false)
                setTimeout(() => {
                    router.push('/#faq')
                }, 500)
            }
        });

        return menu;
    }


    return (
        <ConfigProvider theme={{
            components: {
                Layout: {
                    // Sidebar background color:
                    // https://github.com/ant-design/ant-design/blob/5.0.0/components/layout/style/index.tsx#L101
                    colorBgHeader: sidebarBackgroundColor
                }
            }
        }}>
            <Layout>
                <Head>
                    <meta key="meta-charset" charSet="utf-8" />
                    <meta key="meta-viewport" name="viewport" content="width=device-width, initial-scale=1" />
                    <link key="favicon" rel="icon" href="/favicon.ico" />
                </Head>
                <Header className="bg-white drop-shadow-lg h-auto w-auto">
                    <NavigationBar />
                </Header>
                <Drawer placement="left" open={drawerOpen} onClose={() => setDrawerOpen(false)}>

                    <ConfigProvider theme={{
                        components: {
                            Menu: {
                                // https://github.com/ant-design/ant-design/blob/5.0.0/components/menu/style/theme.tsx#L194
                                colorActiveBarBorderSize: 0
                            }
                        }
                    }}>
                        <Menu mode="inline" items={getMenu()}
                            selectedKeys={selected} onSelect={e => setSelected(e.selectedKeys)} />
                    </ConfigProvider>
                </Drawer>
                <Content className='bg-white'>
                    <div className='bg-white grid grid-cols-3 lg:hidden xl:hidden 2xl:hidden px-8 py-8 items-center drop-shadow-md'>
                        <div>
                            <Button onClick={() => setDrawerOpen(true)} className="duration-300 bg-[#3788FD] hover:bg-black border-none">
                                <FontAwesomeIcon icon={faBars} style={{ color: 'white' }}></FontAwesomeIcon>
                            </Button>
                        </div>
                        <img src="adaptist-blue-logo.png" alt="Logo" style={{ maxWidth: '180px' }}></img>
                    </div>
                    <section id="beranda">
                        <Beranda onClick={showSurveyModal}></Beranda>
                    </section>
                    <section id="tentangkami">
                        <AboutUsSection />
                    </section>
                    <section id="keamanan">
                        <CheckSecuritySection showSurveyModal={showSurveyModal} />
                    </section>
                    <section id="layananKami">
                        {/* <TestimoniesSection /> */}
                        <ProdukSection />
                    </section>
                    <section id="kontak">
                        <ContactUsSection />
                    </section>
                    <section id="faq">
                        <FaqSection />
                    </section>
                    <SurveyModal
                        isSurveyModalOpen={isSurveyModalOpen}
                        handleSurveyModalOk={handleSurveyModalOk}
                        handleSurveyModalCancel={handleSurveyModalCancel}
                        onChangeRadio={(id, val) => onChangeRadio(id, val)}
                        selectedOptions={selectedOptions}
                    />
                    <ScoreModal
                        isModalScoreOpen={isModalScoreOpen}
                        handleScoreModalOk={() => handleScoreModalOk('ok')}
                        handleHubungiKami={() => handleScoreModalOk('hubungiKami')}
                        score={score} />
                </Content>
                <CookieBanner />
                <Footer>
                    <FooterSection />
                </Footer>
            </Layout >
        </ConfigProvider>
    );
};

const HomePage: Page = () => {
    return (
        <div className='bg'
            style={{
                minHeight: '100vh',
                color: 'white'
            }}>
            <Title>Home</Title>
            <Home></Home>
        </div>
    );
}

export default HomePage;