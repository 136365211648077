import Link from "next/link";
import React from "react"

export const FooterSection: React.FC = () => {
    return (
        <div>
            <div className="flex flex-wrap justify-between content-center mx-2 lg:mx-6 mb-20 mt-5 ">
                <div className="lg:w-1/4">
                    <img src="adaptist-blue-logo.png" alt="Logo" style={{ maxWidth: '300px' }} className="mb-8" />
                    <div className="text-[10px] lg:text-base text-wrap mx-5">
                        Adaptist Consulting membantu merancang dan mengimplementasikan sistem perlindungan data pribadi yang baik di dalam perusahaan atau organisasi anda.
                    </div>
                </div>
                <div className='font-semibold lg:w-1/7 mx-5'>
                    <div className="font-bold text-xl lg:text-2xl text-[#3788FD] mt-4">Menu Utama</div>
                    <Link href={"#beranda"} className="flex mt-5 mr-5 text-sm lg:text-base">Beranda</Link>
                    <Link href={"#tentangkami"} className="flex my-2 text-sm lg:text-base">Tentang Kami</Link>
                    <Link href={"#layananKami"} className="flex my-2 text-sm lg:text-base">Layanan Kami</Link>
                    <Link href={"#kontak"} className="flex my-2 text-sm lg:text-base">Kontak</Link>
                    <Link href={"#faq"} className="flex my-2 text-sm lg:text-base">FAQ</Link>
                </div>
                <div className='lg:w-1/5 mx-5'>
                    <div className="text-xl lg:text-2xl font-bold text-[#3788FD] mt-4">Hubungi Kami</div>
                    <div className="flex items-center mt-5">
                        <img src="/telp.png" alt="Icon" style={{ width: 30 }} className="mr-4"></img>
                        <div className="text-[10px] lg:text-sm">
                            (021) 29779333
                        </div>
                    </div>
                    <div className="flex items-center mt-1">
                        <img src="/email.png" alt="Icon" style={{ width: 30 }} className="mr-4"></img>
                        <div className="text-[10px] lg:text-sm">
                            admin@adaptistconsulting.com
                        </div>
                    </div>
                    <div className="flex items-center mt-1">
                        <img src="/maps.png" alt="Icon" style={{ width: 30 }} className="mr-4"></img>
                        <div className="text-[10px] lg:text-sm text-wrap">
                            Brooklyn Office Tower B, 7th Floor, Unit P, Banten 15320
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-between border-t-2 px-6 lg:px-10">
                <p className="text-[10px] lg:text-sm text-[#3788FD] w-1/3 mt-5">Copyright @2023 Adaptist Consulting</p>
                <div className="flex justify-end w-2/3 mt-5">
                    <a href="" target="_blank">
                        <img src="/linkedin.png" alt="Icon" className="mr-4 w-8 lg:w-10 h-8 lg:h-10"></img>
                    </a>
                </div>
            </div>
        </div>
    );
}