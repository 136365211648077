import { Button } from "antd";

interface BerandaProps {
    onClick: () => void;
}

const Beranda: React.FC<BerandaProps> = ({ onClick }) => {

    return (
        <div className="bg-cover" style={{ backgroundImage: 'url(HomePageBackground.png)' }}>
            <div className='xl:pt-40 xl:pl-32 pb-52 lg:pt-40 lg:pl-16 md:pl-8 md:pt-40 sm:pl-2 sm:pt-40 pl-16 pt-20'>
                <p className="pb-1 text-3xl font-bold lg:text-6xl lg:pb-3">Perlindungan Data</p>
                <p className="pb-3 text-3xl font-bold lg:text-6xl lg:pb-4">Dan Privasi</p>
                <div className="border-l-2 border-blue-500 mt-4 mb-8 pl-2 lg:mb-11">
                    <p className="lg:text-sm text-[12px] ml-1">Adaptist Consulting membantu merancang dan</p>
                    <p className="lg:text-sm text-[12px] ml-1">mengimplementasikan sistem perlindungan data pribadi yang baik  </p>
                    <p className="lg:text-sm text-[12px] ml-1">di dalam perusahaan atau organisasi anda.</p>
                </div>
                <p className="text-sm font-bold lg:text-lg pb-3">Bagaimana Keamanan Data di Perusahaan Anda?</p>
                <Button className='bg-white px-5 pb-5 hover:bg-transparent lg:pb-9 lg:pt-2 sm:bg-cover' type="default" onClick={onClick}>
                    <p className="text-[#3788FD] font-bold lg:text-lg sm:text-[8px] sm:mt-1.25 lg:mt-0">Mulai Cek Keamanan Anda</p>
                </Button>
            </div>
        </div>
    )
}

export default Beranda;