import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, Radio, Space } from "antd";
import React from "react";
import { SurveyList } from "./constants";

export const SurveyModal: React.FC<{
    isSurveyModalOpen: boolean,
    handleSurveyModalOk: () => void,
    handleSurveyModalCancel: () => void,
    onChangeRadio: (id: number, val: string) => void,
    selectedOptions: string[]
}> = ({ isSurveyModalOpen, handleSurveyModalOk, handleSurveyModalCancel, onChangeRadio, selectedOptions }) => {
    
    const validateButton = () => {
        const totalValue1 = selectedOptions.filter((value) => Number(value) === 1).length;
        const totalValue2 = selectedOptions.filter((value) => Number(value) === 2).length;
        if (totalValue1 + totalValue2 === SurveyList.length) {
            return false;
        }
        else {
            return true;
        }
    }

    return (
        <Modal width={'80%'}
            open={isSurveyModalOpen}
            onCancel={handleSurveyModalCancel}
            footer={[
                <Button type='primary' className='h-6 lg:h-10 text-[10px] lg:text-base flex justify-start bg-blue-500 mb-4 lg:mb-8 mt-1 lg:mt-2 ml-6 lg:ml-10 text-white' key="submit" onClick={handleSurveyModalOk} disabled={validateButton()}>
                    Kirim dan Hitung Hasilnya
                </Button>,
            ]}
            closable={false}
        >
            <div className='flex flex-row justify-between'>
                <div className='font-bold text-lg lg:text-2xl mb-3 lg:mb-6 ml-4'>
                    Cek Keamanan dan Data Privasi Anda
                </div>
                <FontAwesomeIcon icon={faXmark} color='rgb(59 130 246)'className="w-4 h-4 lg:w-8 lg:h-8 mt-2 lg:mt-1"  onClick={handleSurveyModalCancel} />
            </div>
            <div className='border-t-2 mb-3 lg:mb-5' />
            {SurveyList.map((value, index) => (
                <div key={index} className='py-1 lg:py-2 font-medium px-1 lg:px-0 place-content-start text-left'>
                    <Radio.Group className='mt-2 ml-5'
                        onChange={(val) => onChangeRadio(index, val.target.value)}
                        value={selectedOptions[index]}>
                        <div className='flex flex-row text-[10px] lg:text-base'>
                            <div className='mr-2'>
                                {`${index + 1}.`}
                            </div>
                            <div className='flex flex-col place-content-start'>
                                {value.question}
                                <Space direction='vertical' className='mt-1 lg:mt-2'>
                                    <Radio className="text-[10px] lg:text-base" value={1} >Ya</Radio>
                                    <Radio className="text-[10px] lg:text-base" value={2} >Tidak</Radio>
                                </Space>
                            </div>
                        </div>
                    </Radio.Group>
                </div>
            ))}
        </Modal>
    )
}