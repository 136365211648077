import { Button } from "antd";
import React from "react";

export const CheckSecuritySection: React.FC<{
    showSurveyModal: () => void
}> = ({ showSurveyModal }) => {
    return (
        <div className='bg-[#3788FD] text-white flex flex-row px-10 py-10 lg:px-20 lg:py-20'>
            <div className='w-1/2 mx-4'>
                <div className='text-[10px] lg:text-base font-semibold'>
                    Cek Keamanan Anda
                </div>
                <div style={{ lineHeight: 1.2 }} className='text-2xl lg:text-5xl font-bold my-3 lg:my-6'>
                    Cek Keamanan Dan Privasi Data Anda
                </div>
                <div className='border-t-2 border-white w-12 lg:w-24' />
            </div>
            <div className='w-1/2 mx-4'>
                <div className='text-[10px] lg:text-base font-medium'>
                    Jawablah serangkaian pertanyaan singkat dan mudah tentang keamanan dan privasi data Anda. Ini akan memakan waktu maksimal lima menit. Anda kemudian akan menerima informasi untuk meningkatkan keamanan dan privasi data Anda, memberi tahu Anda tentang layanan yang dapat membantu, dan menemukan kisah-kisah yang dapat menginspirasi Anda. Rekomendasi kami didasarkan pada jawaban Anda, jadi jawablah seakurat mungkin.
                </div>
                <div className='text-[10px] lg:text-base font-medium my-3 lg:my-5'>
                    Tekan button dibawah untuk mengetahui apakah keamanan
                    dan privasi data kamu sudah cukup
                </div>
                <Button type="primary"
                    onClick={showSurveyModal}
                    className='text-[#3788FD] font-bold bg-white text-[8px] lg:text-base h-6 lg:h-10'>
                    Mulai Cek Keamanan Anda
                </Button>
            </div>
        </div>
    )
}