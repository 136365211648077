import { BackendApiUrl } from '@/functions/BackendApiUrl';
import { useFetchWithAccessToken } from '@/functions/useFetchWithAccessToken';
import { Button, notification } from 'antd';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

interface SendEmailProps {
  namaDepan: string;
  namaBelakang: string;
  email: string;
  isi: string;
}

export const ContactUsSection = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const fetch = useFetchWithAccessToken();
  const { handleSubmit } = useForm<SendEmailProps>({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);


  const schema = z.object({
    namaDepan: z.string().nonempty("Nama Depan tidak boleh kosong"),
    namaBelakang: z.string().nonempty("Nama Belakang tidak boleh kosong"),
    email: z.string().nonempty("Email tidak boleh kosong").email("Email tidak valid"),
    isi: z.string().nonempty("Pesan tidak boleh kosong")
  });

  const onSubmit = async () => {
    setLoading(true);
    const payload: SendEmailProps = {
      namaDepan: firstName,
      namaBelakang: lastName,
      email: email,
      isi: description
    };

    try {
      const formValues = { namaDepan: firstName, namaBelakang: lastName, email, isi: description };
      schema.parse(formValues);

      const response = await fetch.fetchPOST(BackendApiUrl.sendEmail, payload);
      if (response.data?.['success'] === 'Success') {
        notification.success({
          message: '',
          description: 'Email berhasil terkirim',
          placement: 'bottomRight',
        });
        setFirstName('');
        setLastName('');
        setEmail('');
        setDescription('');
        setErrors([]);
      }
    } catch (error) {
      if (error instanceof z.ZodError) {
        setErrors(error.errors.map(err => err.message));
      } else {
        notification.error({
          message: 'Gagal',
          description: 'Gagal mengirim email',
          placement: 'bottomRight',
        });
      }
    }
    setLoading(false);
  };

  return (
    <div className="h-auto bg-cover" style={{ backgroundImage: 'url(/bg-kontak-kami.png)' }}>
      <div className="p-16 max-w-100 lg:mx-6">
        <h2 className="text-[16px] font-bold mb-2 font-poppins text-blue-500">Kontak Kami</h2>
        <div className="flex flex-wrap">
          <div className="flex-1 mr-16 max-w-auto items-start">
            <div className='font-sans font-bold text-[40px]'>Jangan Ragu Untuk Menghubungi Kami!</div>
            <div className="border-t-2 border-blue-500 w-16 mb-10 mt-5"></div>
          </div>
          <div className='grid-cols-1'>
            <div className="flex max-w-[247px] pt-3">
              <div className="flex items-start mb-auto">
                <img src="/maps.png" alt="Icon" style={{ width: 30 }} className=""></img>
                <div className="text-[16px] lg:text-sm text-wrap ml-3">
                  Brooklyn Office Tower B, 7th Floor, Unit P, Banten 15320
                </div>
              </div>
            </div>

            <div className="flex flex-col max-w-xs pt-3">
              <div className="flex items-center">
                <img src="/telp.png" alt="Icon" style={{ width: 30 }} className="mr-4 my-1"></img>
                <div className="text-[16px] lg:text-sm">
                  (021) 29779333
                </div>
              </div>
              <div className="flex items-center mt-3">
                <img src="/email.png" alt="Icon" style={{ width: 30 }} className="mr-4 my-1"></img>
                <div className="text-[16px] lg:text-sm">
                  admin@adaptistconsulting.com
                </div>
              </div>
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-between flex-wrap mb-4 gap-5  mt-5">
            <div className="flex-auto w-full md:w-auto">
              <label className="block">
                <input
                  disabled={loading}
                  type="text"
                  className="w-full h-12 rounded p-5 bg-gray-300 bg-opacity-70 font-sans text-white placeholder-white"
                  value={firstName}
                  placeholder='Nama Depan...'
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </label>
            </div>
            <div className="flex-auto w-full md:w-auto">
              <label className="block">
                <input
                  disabled={loading}
                  type="text"
                  className="w-full h-12 rounded p-5 bg-gray-300 bg-opacity-70 font-sans text-white placeholder-white"
                  value={lastName}
                  placeholder='Nama Belakang...'
                  onChange={(e) => setLastName(e.target.value)}
                />
              </label>
            </div>
            <div className="flex-auto w-full md:w-auto">
              <label className="block">
                <input
                  disabled={loading}
                  type="text"
                  className="w-full h-12 rounded p-5 bg-gray-300 bg-opacity-70 font-sans text-white placeholder-white"
                  value={email}
                  placeholder='Alamat Email...'
                  onChange={(e) => setEmail(e.target.value)}
                />
              </label>
            </div>
          </div>
          <div className="mb-4">
            <label className="block">
              <textarea
                disabled={loading}
                className="w-full h-40 rounded p-5 bg-gray-300 bg-opacity-70 text-white placeholder-white"
                value={description}
                placeholder='Tulis...'
                onChange={(e) => setDescription(e.target.value)}
              />
            </label>
          </div>
          <div>
            <Button loading={loading} htmlType="submit" className="bg-blue-500 w-full text-white px-4 rounded">Kirim</Button>
            {errors.map((error, index) => (
              <div key={index} className=" text-sm mt-2" style={{ color: "rgb(255, 0, 0)" }}>
                {error}
              </div>
            ))}
          </div>
        </form>
      </div>
    </div>
  );
};
