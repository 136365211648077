import React from "react";

export const AboutUsSection: React.FC = () => {
    return (
        <div>
            <div className="flex flex-col justify-center items-center">
                <div className="text-[#3788FD] pt-12 pb-3 text-sm lg:text-lg font-bold flex justify-center lg:pt-14 lg:pb-2">
                    Tentang Kami
                </div>
                <div className="text-black text-center text-wrap text-3xl font-bold mb-3 lg:text-5xl lg:mb-5 flex justify-center">
                    Kenapa Perlu Konsultan Proteksi Perlindungan Data
                </div>
                <div className="border-b-2 border-[#3788FD] w-24 mx-auto"></div>
            </div>
            <div className="flex flex-col px-10 py-10 mx-4 text-black lg:hidden">
                <div className="text-base font-bold pb-3">
                    Tujuan
                </div>
                <div>
                    <ul className="flex flex-col">
                        <li className="inline-flex pb-3 items-center">
                            <img src="checklist.png" alt="" className="text-[#3788FD] border-2 border-solid border-gray-500 rounded-full px-1 py-1 object-contain w-7 h-7"/>
                            <p className="text-[13px] ml-4 font-semibold">Patuh regulasi perlindungan data yang selalu berubah</p>
                        </li>
                        <li className="inline-flex pb-3 items-center">
                            <img src="checklist.png" alt="" className="text-[#3788FD] border-2 border-solid border-gray-500 rounded-full px-1 py-1 object-contain w-7 h-7"/>
                            <p className="text-[13px] ml-4 font-semibold">Pantau kematangan perlindungan data Anda</p>
                        </li>
                        <li className="inline-flex pb-3 items-center">
                            <img src="checklist.png" alt="" className="text-[#3788FD] border-2 border-solid border-gray-500 rounded-full px-1 py-1 object-contain w-7 h-7"/>
                            <p className="text-[13px] ml-4 font-semibold">Identifikasi titik lemah keamanan dan kepatuhan data</p>
                        </li>
                        <li className="inline-flex pb-3 items-center">
                            <img src="checklist.png" alt="" className="text-[#3788FD] border-2 border-solid border-gray-500 rounded-full px-1 py-1 object-contain w-7 h-7"/>
                            <p className="text-[13px] ml-4 font-semibold">Membantu mempertahankan standar pelindungan data</p>
                        </li>
                        <li className="inline-flex pb-3 items-center">
                            <img src="checklist.png" alt="" className="text-[#3788FD] border-2 border-solid border-gray-500 rounded-full px-1 py-1 object-contain w-7 h-7"/>
                            <p className="text-[13px] ml-4 font-semibold">Bangun kepercayaan dan loyalitas pelanggan</p>
                        </li>
                    </ul>
                </div>
                <div className="text-base lg:text-lg font-bold pb-3">
                    Hasil
                </div>
                <div>
                    <ul className="flex flex-col">
                        <li className="inline-flex pb-3 items-center">
                            <img src="checklist.png" alt="" className="text-[#3788FD] border-2 border-solid border-gray-500 rounded-full px-1 py-1 object-contain w-7 h-7"/>
                            <p className="text-[13px] ml-4 font-semibold">Akses langsung ke Pakar dan tim profesional perlindungan data</p>
                        </li>
                        <li className="inline-flex pb-3 items-center">
                            <img src="checklist.png" alt="" className="text-[#3788FD] border-2 border-solid border-gray-500 rounded-full px-1 py-1 object-contain w-7 h-7"/>
                            <p className="text-[13px] ml-4 font-semibold">Para ahli berpengalaman telah melakukan prosesnya, jadi Anda bisa tenang</p>
                        </li>
                        <li className="inline-flex pb-3 items-center">
                            <img src="checklist.png" alt="" className="text-[#3788FD] border-2 border-solid border-gray-500 rounded-full px-1 py-1 object-contain w-7 h-7"/>
                            <p className="text-[13px] ml-4 font-semibold">Mencegah potensi kegagalan kepatuhan di seluruh organisasi Anda</p>
                        </li>
                        <li className="inline-flex pb-3 items-center">
                            <img src="checklist.png" alt="" className="text-[#3788FD] border-2 border-solid border-gray-500 rounded-full px-1 py-1 object-contain w-7 h-7"/>
                            <p className="text-[13px] ml-4 font-semibold">Pengurangan substansial dalam risiko regulasi dan reputasi</p>
                        </li>
                        <li className="inline-flex items-center pb-3">
                            <img src="checklist.png" alt="" className="text-[#3788FD] border-2 border-solid border-gray-500 rounded-full px-1 py-1 object-contain w-7 h-7"/>
                            <p className="text-[13px] ml-4 font-semibold">Peningkatan kepercayaan, peningkatan keterlibatan, peningkatan reputasi</p>
                        </li>
                    </ul>
                </div>
                <div className="flex justify-center items-center pt-5">
                    <img src="tentangKami.png" alt="" className="max-w-full h-auto object-contain rounded-xl" />
                </div>
            </div>
            <div className="hidden lg:flex lg:flex-row lg:px-20 px-10 py-10 lg:py-20">
                <div className="text-black w-1/2 mx-4">
                    <div className="lg:text-lg text-base font-bold pb-3 lg:pb-5">
                        Tujuan
                    </div>
                    <div>
                        <ul>
                            <li  className="inline-flex pb-3 lg:pb-6 items-center">
                                <img src="checklist.png" alt="" className="text-[#3788FD] border-2 border-solid border-gray-500 rounded-full px-1 py-1 object-contain w-7 h-7 lg:w-8 lg:h-8"/>
                                <p className="text-[13px] lg:text-sm ml-4 font-semibold">Patuh regulasi perlindungan data yang selalu berubah</p>
                            </li>
                            <li  className="inline-flex pb-3 lg:pb-6 items-center">
                                <img src="checklist.png" alt="" className="text-[#3788FD] border-2 border-solid border-gray-500 rounded-full px-1 py-1 object-contain w-7 h-7 lg:w-8 lg:h-8"/>
                                <p className="text-[13px] lg:text-sm ml-4 font-semibold">Pantau kematangan perlindungan data Anda</p>
                            </li>
                            <li  className="inline-flex pb-3 lg:pb-6 items-center">
                                <img src="checklist.png" alt="" className="text-[#3788FD] border-2 border-solid border-gray-500 rounded-full px-1 py-1 object-contain w-7 h-7 lg:w-8 lg:h-8"/>
                                <p className="text-[13px] lg:text-sm ml-4 font-semibold">Identifikasi titik lemah keamanan dan kepatuhan data</p>
                            </li>
                            <li  className="inline-flex pb-3 lg:pb-6 items-center">
                                <img src="checklist.png" alt="" className="text-[#3788FD] border-2 border-solid border-gray-500 rounded-full px-1 py-1 object-contain w-7 h-7 lg:w-8 lg:h-8"/>
                                <p className="text-[13px] lg:text-sm ml-4 font-semibold">Membantu mempertahankan standar pelindungan data</p>
                            </li>
                            <li  className="inline-flex pb-3 lg:pb-6 items-center">
                                <img src="checklist.png" alt="" className="text-[#3788FD] border-2 border-solid border-gray-500 rounded-full px-1 py-1 object-contain w-7 h-7 lg:w-8 lg:h-8"/>
                                <p className="text-[13px] lg:text-sm ml-4 font-semibold">Bangun kepercayaan dan loyalitas pelanggan</p>
                            </li>
                        </ul>
                    </div>
                    <div className="text-base lg:text-lg font-bold pb-3 lg:pb-5 lg:pt-5">
                        Hasil
                    </div>
                    <div>
                        <ul>
                            <li  className="inline-flex pb-3 lg:pb-6 items-center">
                                <img src="checklist.png" alt="" className="text-[#3788FD] border-2 border-solid border-gray-500 rounded-full px-1 py-1 object-contain w-7 h-7 lg:w-8 lg:h-8"/>
                                <p className="text-[13px] lg:text-sm ml-4 font-semibold">Akses langsung ke Pakar dan tim profesional perlindungan data</p>
                            </li>
                            <li  className="inline-flex pb-3 lg:pb-6 items-center">
                                <img src="checklist.png" alt="" className="text-[#3788FD] border-2 border-solid border-gray-500 rounded-full px-1 py-1 object-contain w-7 h-7 lg:w-8 lg:h-8"/>
                                <p className="text-[13px] lg:text-sm ml-4 font-semibold">Para ahli berpengalaman telah melakukan prosesnya, jadi Anda bisa tenang</p>
                            </li>
                            <li  className="inline-flex pb-3 lg:pb-6 items-center">
                                <img src="checklist.png" alt="" className="text-[#3788FD] border-2 border-solid border-gray-500 rounded-full px-1 py-1 object-contain w-7 h-7 lg:w-8 lg:h-8"/>
                                <p className="text-[13px] lg:text-sm ml-4 font-semibold">Mencegah potensi kegagalan kepatuhan di seluruh organisasi Anda</p>
                            </li>
                            <li  className="inline-flex pb-3 lg:pb-6 items-center">
                                <img src="checklist.png" alt="" className="text-[#3788FD] border-2 border-solid border-gray-500 rounded-full px-1 py-1 object-contain w-7 h-7 lg:w-8 lg:h-8"/>
                                <p className="text-[13px] lg:text-sm ml-4 font-semibold">Pengurangan substansial dalam risiko regulasi dan reputasi</p>
                            </li>
                            <li  className="inline-flex items-center pb-3 lg:pb-6">
                                <img src="checklist.png" alt="" className="text-[#3788FD] border-2 border-solid border-gray-500 rounded-full px-1 py-1 object-contain w-7 h-7 lg:w-8 lg:h-8"/>
                                <p className="text-[13px] lg:text-sm ml-4 font-semibold">Peningkatan kepercayaan, peningkatan keterlibatan, peningkatan reputasi</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="w-1/2 mx-4 flex justify-center items-center lg:justify-end">
                    <img src="tentangKami.png" alt="" className="max-w-full h-auto object-contain rounded-xl" />
                </div>
            </div>
        </div>
    )
}