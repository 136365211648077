import { faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Modal } from "antd"
import React from "react"

export const ScoreModal: React.FC<{
    handleScoreModalOk: () => void,
    handleHubungiKami: () => void,
    isModalScoreOpen: boolean,
    score: number
}> = ({ handleScoreModalOk, handleHubungiKami, isModalScoreOpen, score }) => {
    return (
        <Modal width={'80%'}
            open={isModalScoreOpen}
            onCancel={handleScoreModalOk}
            footer={[]}
            closable={false}
        >
            <div className='flex flex-row justify-between'>
                <div className='font-bold text-lg lg:text-2xl mb-3 lg:mb-6'>
                    Hasil dan Nilai Keamanan dan  Data Privasi Anda
                </div>
                <FontAwesomeIcon icon={faXmark} color='rgb(59 130 246)' className="w-4 h-4 lg:w-8 lg:h-8 mt-2 lg:mt-1"  onClick={handleScoreModalOk} />
            </div>
            <div className='border-t-2 mb-5' />
            <div className='text-center px-1 lg:px-5'>
                <div className='text-[10px] lg:text-base text-[#3788FD] font-semibold'>
                    Nilai Keamanan dan Data Privasi Anda:
                </div>
                <div className='text-3xl lg:text-6xl text-[#3788FD] my-5 font-bold'>
                    {score}
                </div>
                <div className='text-[10px] lg:text-base mb-5'>
                    Perlu diskusi lebih lanjut?
                </div>
            </div>
            <div className='flex justify-center'>
                <Button type='primary' className='h-6 lg:h-10 bg-[#3788FD] mt-2 text-white text-[10px] lg:text-base' key="submit" onClick={handleHubungiKami} >
                    Hubungi Kami
                </Button>
            </div>
        </Modal>
    )
}