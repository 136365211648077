export const ColorPalette = {
    primaryBlack100: '#1E293B',
    primaryBlack80: '#4B5462',
    primaryBlack60: '#787F89',
    primaryBlack30: '#BCBFC5',
    primaryBlack10: '#E9EAEC',
    primaryGreen100: '#0D9488',
    primaryGreen80: '#3DA9A0',
    primaryGreen60: '#6EBFB8',
    primaryGreen30: '#B7DFDC',
    primaryGreen10: '#E7F5F4',
    primaryWhite: '#F8FAFC',
    alertPrimary: '#1D93FF',
    alertDanger: '#CE3636',
    alertSuccess: '#0D9488',
    alertWarning: '#F5B307',
};

export const MenuList = [
    { label: "Beranda", href: "#beranda" },
    { label: "Tentang Kami", href: "#tentangkami" },
    { label: "Layanan Kami", href: "#layananKami" },
    { label: "Kontak", href: "#kontak" },
    { label: "FAQ", href: "#faq" },
]

export const SurveyList = [
    { question: "Apakah Perusahaan Anda sudah memiliki struktur tata kelola privasi (Privacy Governance Structure) beserta dengan peran dan tanggung jawab yang jelas?" },
    { question: "Apakah Perusahaan Anda sudah memiliki Petugas Perlindungan Data (Data Protection / Privacy Officer )?" },
    { question: "Apakah Perusahaan Anda sudah memiliki catatan atau rekaman atas seluruh kegiatan pemrosesan Data Pribadi?" },
    { question: "Apakah Perusahaan Anda sudah memiliki sistem penyimpanan data pribadi yang terpusat, akurat, lengkap dan mudah diakses?" },
    { question: "Apakah Perusahaan Anda sudah memiliki prosedur untuk melaksanakan Penilaian Risiko Privasi (Privacy Risk / Impact Assessment )?" },
    { question: "Apakah Perusahaan Anda sudah mempertimbangkan dan menyertakan aspek privasi dalam melakukan Penilaian Risiko?" },
    { question: "Apakah Perusahaan Anda sudah mengidentifikasi regulasi privasi data yang berlaku untuk Perusahaan Anda?" },
    { question: "Apakah Perusahaan Anda sudah melakukan penilaian kepatuhan terhadap regulasi privasi data yang berlaku sesuai dengan kegiatan operasional bisnis?" },
    { question: "Apakah Perusahaan Anda sudah memiliki standardisasi dalam menyediakan notifikasi (notice ) dan persetujuan (consent) kepada pemilik data pribadi (data subjects)?" },
    { question: "Apakah Perusahaan Anda sudah memiliki catatan untuk menyimpan persetujuan (consent) yang didapatkan dari pemilik data pribadi (data subjects)? (misalnya pelanggan)" },
    { question: "Apakah Perusahaan Anda sudah memiliki mekanisme untuk mengelola proses notifikasi (notice) dan permintaan persetujuan (consent)?" },
    { question: "Apakah Perusahaan Anda sudah memiliki kebijakan formal terkait klasifikasi data?" },
    { question: "Apakah Perusahaan Anda sudah memiliki kebijakan formal terkait retensi data?" },
    { question: "Apakah Perusahaan Anda sudah memiliki kebijakan formal terkait privasi data?" },
    { question: "Apakah Perusahaan Anda sudah memiliki proses formal terkait pertukaran data pribadi antar entitas Perusahaan Anda yang berada di luar Indonesia?" },
    { question: "Apakah Perusahaan Anda sudah memiliki prosedur formal untuk mengelola aktivitas permintaan akses terhadap informasi data pribadi (subject access request)?" },
    { question: "Apakah Perusahaan Anda sudah memiliki pencatatan untuk menyimpan aktivitas permintaan akses terhadap informasi data pribadi (subject access request)?" },
    { question: "Apakah Perusahaan Anda sudah memiliki kontrol keamanan terhadap aktivitas manajemen privasi data?" },
    { question: "Apakah Perusahaan Anda sudah memiliki prosedur formal terkait penganganan insiden?" },
    { question: "Apakah Perusahaan Anda sudah memiliki prosedur terkait penilaian risiko terhadap pihak ketiga? (misalnya vendor, kontraktor)" },
    { question: "Apakah Perusahaan Anda sudah melakukan penilaian risiko terhadap pihak ketiga? (misalnya vendor, kontraktor)" },
    { question: "Apakah Perusahaan Anda sudah melakukan pelatihan terkait privasi data (data privacy )?" },
]