import Link from "next/link"
import React from "react"

export const MenuNav: React.FC<{ label: string, href: string }> = ({ label, href }) => {
    return (
        <Link href={href} className='px-8'>
            <p className='text-black font-semibold text-base mx-2 block lg:inline-block lg:mt-0'>
                {label}
            </p>
        </Link>
    )
}