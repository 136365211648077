import React, { useEffect, useState } from 'react';
import { getLocalStorage, setLocalStorage } from '../functions/Helper';

export default function CookieBanner() {
  const [cookieConsent, setCookieConsent] = useState<boolean | null>();

  useEffect(() => {
    const storedCookieConsent = getLocalStorage('cookie_consent', null);
    setCookieConsent(storedCookieConsent);
  }, []);

  useEffect(() => {
    if(cookieConsent != undefined){
      setLocalStorage('cookie_consent', cookieConsent);
    }
    
  }, [cookieConsent]);

  return (
    <div
      className={`${
        cookieConsent !== null
          ? 'hidden'
          : 'flex flex-col fixed inset-x-0 bottom-0 z-20 justify-between gap-x-8 gap-y-4 bg-black bg-opacity-80 p-6 ring-1 ring-gray-900/10 md:flex-row md:items-center md:px-8 lg:px-8 xs:block'
      }`}
    >
      <p className="max-w-5xl text-sm leading-6 text-white">
      Kami menggunakan cookie untuk meningkatkan pengalaman Anda di situs web kami. Klik &quot;Terima Cookie&quot; bila Anda setuju menggunakan cookie di web kami.
      </p>

      <div className="flex gap-2">
        <div className="mr-16 flex flex-none items-center gap-x-5">
          <button
            onClick={() => setCookieConsent(true)}
            type="button"
            className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold border-2 border-blue-500 text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
          >
            Terima Cookie
          </button>
          <button
            onClick={() => setCookieConsent(false)}
            type="button"
            className="rounded-md text-sm px-3 py-2 font-semibold leading-6 text-white border-2 border-blue-500"
          >
            Tolak Cookie
          </button>
        </div>
      </div>
    </div>
  );
}
