import { BackendApiUrl } from '@/functions/BackendApiUrl';
import { useSwrFetcherWithAccessToken } from '@/functions/useSwrFetcherWithAccessToken';
import { useState } from 'react';
import useSWR from 'swr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

interface FaqModel {
  id: string;
  title: string;
  description: string;
}

export const FaqSection = () => {
  const swrFetcher = useSwrFetcherWithAccessToken();
  const { data: faqData } = useSWR<FaqModel[]>(BackendApiUrl.getFaq, swrFetcher);
  const [expandedFaqIds, setExpandedFaqIds] = useState<string[]>([]);
  const [hoveredFaqId, setHoveredFaqId] = useState<string | null>(null);

  const toggleFaq = (faqId: string) => {
    if (expandedFaqIds.includes(faqId)) {
      setExpandedFaqIds(expandedFaqIds.filter(id => id !== faqId));
    } else {
      setExpandedFaqIds([...expandedFaqIds, faqId]);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center mb-20 mt-10">
      <div className="justify-center text-center text-sm lg:text-lg lg:mb-1 font-bold text-blue-500 mt-4">FAQ</div>
      <div className='justify-center text-center mt-0 font-bold mb-3 text-2xl lg:text-5xl lg:mb-5 text-black text-wrap px-20'>
        Temukan Jawaban atas Pertanyaan Anda
      </div>
      <div className="border-b-2 border-blue-500 w-24 mx-auto mb-4"></div>
      <div className='w-4/5 mt-8'>
        {faqData &&
          faqData.map((faq, index) => (
            <div key={faq.id} className={`mb-0 border-b border-blue-500 overflow-hidden ${index === 0 && 'border-t'}`}>
              <div
                className={`cursor-pointer flex items-center justify-between text-sm lg:text-lg p-6 ${expandedFaqIds.includes(faq.id) || hoveredFaqId === faq.id ? 'bg-blue-500 text-white' : 'bg-white text-black'} transition-colors duration-300`}
                onClick={() => toggleFaq(faq.id)}
                onMouseEnter={() => setHoveredFaqId(faq.id)}
                onMouseLeave={() => setHoveredFaqId(null)}
              >
                <div>{faq.title}</div>
                <div
                  className={`text-blue-500 p-2 transform ${expandedFaqIds.includes(faq.id) ? 'text-white rotate-90' : hoveredFaqId === faq.id ? 'text-white' : ''}`}
                  onClick={() => toggleFaq(faq.id)}
                >
                  <FontAwesomeIcon icon={faPlay} />
                </div>
              </div>
              {expandedFaqIds.includes(faq.id) && (
                <div className="text-black pb-8 pl-8 pt-8 text-sm lg:text-lg">
                  {faq.description}
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};