import React from 'react';

const produk = [
  {
    id: 1,
    imageSrc: '/produk-secure.png',
    title: 'Meningkatkan Keamanan Data',
    description: 'Membantu perusahaan dalam proses perencanaan guna meningkatkan keamanan aktifitas pemrosesaan data pribadi',
  },
  {
    id: 2,
    imageSrc: '/produk-checklist.png',
    title: 'Membantu Kepatuhan Terhadap Regulasi Pemerintah',
    description: 'Membantu perusahaan atau organisasi untuk melakukan evalusi agar dapat patuh terhadap UU Nomor 27 Tahun 2022 terkait',
  },
  {
    id: 3,
    imageSrc: '/produk-manage-profile.png',
    title: 'Melindungi dan Meningkatkan Reputasi Perusahaan atau Organisasi',
    description: 'Dengan memberikan konsultasi dan dukungan teknis terhadap implementasi Undang-undang perlindungan data pribadi',
  },
  {
    id: 4,
    imageSrc: '/produk-target.png',
    title: 'Memberikan Kepuasan dan Kepercayaan Pengguna',
    description: 'Berkomitmen untuk memberikan pelayanan yang memuaskan dan membangun kepercayaan pengguna dengan mengelola data pribadi mereka secara aman dan sesuai dengan regulasi yang berlaku',
  },
];

export const ProdukSection = () => {

  return (
    <div id='testimony'>
      <div className="bg-gray-200 px-16 py-16">
        <div className='flex flex-col items-center'>
          <p className="text-lg font-bold mb-0 font-poppins text-blue-500">Layanan Kami</p>
          <h1 className="text-black text-center text-wrap text-3xl font-bold mb-3 lg:text-5xl lg:mb-5 flex justify-center">Mitra Perlindungan Data Pribadi Organisasi Anda</h1>
          <div className="border-t-2 border-blue-500 w-16 mb-16"></div>

          <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
            {produk.map((value) => (
              <div key={value.id} className='bg-blue-500 p-5 m-2 rounded-lg'>
                <img src={value.imageSrc} alt={value.title} className='rounded-xl object-cover max-w-[80px] max-h-[80px] filter brightness-0 invert' />
                <p className='text-white mt-2 font-bold text-xl'>{value.title}</p>
                <p className='text-white mt-2 font-semibold'>{value.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
